@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-striped-rows tr:nth-child(even) td {
  background-color: #fbfbfb;
}
.table-striped-rows thead {
  background-color: #f1f1f1;
}
.district {
  font-weight: 500;
}
.ant-table-title {
  text-align: left;
  font-size: medium;
  font-weight: bolder;
}

.district,
.morning,
.evening,
.diagnosis,
.prescription,
.total {
  font-size: 1.25rem;
  white-space: nowrap;
}

.ant-table-thead > tr > th.total {
  color: currentColor;
  font-size: 1.25rem;
}
.ant-table-thead > tr > th.totalNested {
  color: currentColor;
  font-size: 14px;
}
/* .totalNested:not(:first-child),  */
/* .total:not(:first-child)  */
.total{
  color: green;
  font-weight: 600;
  font-size: 1.625rem;
}
.totalNested{
  color: green;
  font-weight: 600;
  font-size: 1.125rem;
}

.totalGrouped {
  color: green;
  background-color: #fafafa;
  font-weight: 500;
  font-size: 1.15rem;
  white-space: nowrap;
}
